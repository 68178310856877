
import Upload2 from "@/components/upload/Upload2.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import moment from "moment/moment";
import { useRoute } from "vue-router";
import SendBillingDialog from "@/components/dialogs/SendBillingDialog.vue";
import i18n from "@/core/plugins/i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default {
  name: "Documents",
  components: {
    Upload2,
    TableBasicSelect,
    SendBillingDialog,
  },

  setup() {
    const { t } = useI18n();
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();
    const locale = computed(() => i18n.locale.value);
    const fileTypeId = ref("");
    const url = ref("");
    const allFiles = ref([]);
    const filteredData = ref([]);
    const fileTypes = ref([]);
    const search = ref("");
    const dialogVideo = ref(false);
    const dialogAudio = ref(false);
    let headers = ref([
      { column: t("iname"), key: "name", size: "extra_large" },
      { column: t("uploadDate"), key: "created_at" },
      { column: t("itype"), key: "file_type_id" },
    ]);
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      last_page: 1,
      total: 1,
    });

    const officesData = computed(() => store.getters.Offices);
    const officesIds = [] as any;

    const onSendEmail = async (element: any) => {
      let emails = [] as any;
      const { data } = await ApiService.query("/api/contacts", {
        params: {
          entity_id: [route.params.id],
          contact_type_id:
            element.file_type_id == "Factura"
              ? [5]
              : element.file_type_id == "Comercial"
              ? [2]
              : [],
        },
      });
      data.contacts.forEach((x: any) => {
        const communications = x.communications.filter(
          (y: any) => y.media.name === "Email"
        );
        communications.forEach((y: any) => {
          if (y.value && y.value !== "") {
            emails.push(y.value);
          }
        });
      });
      store.commit("setEmailItem", element);
      store.commit("setMails", emails);
      store.commit("setSendBillingDialog", true);
    };

    const tableButtons = ref([
      {
        name: "Descargar" as never,
        type: "DOWNLOAD" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Reproducir" as never,
        type: "PLAY" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "REMOVE") {
        ApiService.delete(
          `/api/action_files/${param1}?force_delete=${true}`
        ).then(function ({ data }) {
          const index = allFiles.value.findIndex((x: any) => x.id === param1);
          allFiles.value.splice(index, 1);
          filteredData.value.splice(index, 1);
        });
      }
      if (param2 == "PLAY") {
        if (param1.type === "video") {
          url.value = `${param1.path}`;
          dialogVideo.value = true;
        } else if (param1.type === "audio") {
          url.value = `${param1.path}`;
          dialogAudio.value = true;
        }
      }
      if (param2 == "DOWNLOAD") {
        store.commit("setLoadingStatus", true);
        ApiService.setResponseTypeBlob();
        ApiService.get(`/api/action_files/download/${param1.id}`)
          .then((data) => {
            let fileUrl = window.URL.createObjectURL(data);
            let fileLink = document.createElement("a");

            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              `${
                param1.path
                  ? param1.path.split("/")[1]
                  : param1.name
                  ? param1.name
                  : "File"
              }`
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            store.commit("setLoadingStatus", false);
          })
          .catch((error) => {
            Swal.fire({
              text: t("document_error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            console.log(error);
            store.commit("setLoadingStatus", false);
          });
      }
    };

    const updateFiles = () => {
      getFiles();
    };

    const onSearch = () => {
      if (search.value) {
        filteredData.value = allFiles.value.filter(
          (x) =>
            JSON.stringify(x)
              .toLowerCase()
              .indexOf(search.value.toLowerCase()) !== -1
        );
        pagination.value.page = 1;
        pagination.value.total = filteredData.value.length;
        pagination.value.last_page = 1;
      } else {
        getFiles();
      }
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getFiles();
    };
    const currentPageChange = (val) => {
      pagination.value.page = val;
      getFiles();
    };

    const getAllFiles = () => {
      ApiService.get(`/api/action_files`).then(({ data }) => {
        let tempFiles = data.filter(
          (obj: any) =>
            obj.file_type_id !== null && obj.entity_id == route.params.id
        );
        tempFiles.forEach(function (item) {
          item.file_type_id = item.file_type.name;
          item.created_at = moment(item.created_at).format("DD/MM/YYYY");
        });
        allFiles.value = tempFiles;
      });
    };

    const getFiles = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/action_files/lists`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: search.value,
          entity_id: route.params.id,
        },
      })
        .then(({ data }) => {
          let tempFiles = data.action_files;
          tempFiles.forEach(function (item) {
            item.file_type_id = item.file_type.name;
            item.created_at = moment(item.created_at).format("DD/MM/YYYY");
            delete item["type"];
            delete item["action"];
            delete item["action_id"];
            delete item["deleted_at"];
            delete item["description"];
            delete item["entity_id"];
            delete item["path"];
            delete item["extension"];
            delete item["type"];
            delete item["updated_at"];
            delete item["file_type"];
          });
          pagination.value.total = data.pagination.total;
          pagination.value.page = data.pagination.current_page;
          pagination.value.last_page = data.pagination.last_page;
          pagination.value.rowsPerPage = data.pagination.rowsPerPage;
          filteredData.value = tempFiles;
          store.commit("setLoadingStatus", false);
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    watch(
      () => locale.value,
      (first) => {
        headers = ref([
          { column: t("iname"), key: "name", size: "extra_large" },
          { column: t("uploadDate"), key: "created_at" },
          { column: t("itype"), key: "file_type_id" },
        ]);
      }
    );
    watch(
      () => headers.value,
      (first) => {
        headers = ref([
          { column: t("iname"), key: "name", size: "extra_large" },
          { column: t("uploadDate"), key: "created_at" },
          { column: t("itype"), key: "file_type_id" },
        ]);
      }
    );
    watch(
      () => officesData.value,
      (first) => {
        first.forEach((x: any) => {
          officesIds.push(x.id);
        });
      }
    );

    onMounted(() => {
      ApiService.get(`/api/file-types/lists`).then(({ data }) => {
        fileTypes.value = data.file_types;
      });
      getAllFiles();
      getFiles();
    });

    return {
      fileTypeId,
      fileTypes,
      allFiles,
      actionsButton,
      updateFiles,
      getFiles,
      headers,
      tableButtons,
      pagination,
      onSearch,
      search,
      filteredData,
      getAllFiles,
      currentPageChange,
      setItemsPerPage,
      dialogVideo,
      dialogAudio,
      url,
      route,
      user,
      onSendEmail,
      officesIds,
    };
  },
};
