
import { onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import TableFiles from "@/components/tables/TableFiles.vue";
import { useStore } from "vuex";

export default {
  name: "Upload2",
  props: {
    files: {
      type: [Number, Array],
      default: undefined,
    },
    action_id: {
      type: Number,
      default: "",
    },
    type_id: {
      type: Number,
      default: "",
    },
    fromEntity: {
      type: Boolean,
      default: false,
    },
    entity_id: {
      type: Number,
      default: "",
    },
  },
  components: {
    TableFiles,
  },

  setup(props, { emit }) {
    const logs = ref([]) as any;
    const filename = ref("");
    const file = ref("");
    const success = ref("");
    const store = useStore();

    const uploadFile = (event) => {
      store.commit("setLoadingStatus", true);
      const frm = new FormData();
      const files = [] as any;
      for (var i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
        frm.append("action_id", props.action_id);
        frm.append("file_type_id", props.type_id);
        frm.append("entity_id", props.entity_id);
        frm.append("files[]", event.target.files[i]);
      }
      ApiService.postAttach("/api/action_files", frm).then(function ({ data }) {
        data.forEach((x) => {
          logs.value.push(x);
        });
        emit("updateFiles");
        store.commit("setLoadingStatus", false);
      });
    };

    const removeFile = (id) => {
      const index = logs.value.findIndex((x) => x.id === id);
      logs.value.splice(index, 1);
    };

    onMounted(() => {
      props.files.forEach((x) => {
        logs.value.push(x);
      });
    });

    return {
      logs,
      filename,
      file,
      success,
      uploadFile,
      removeFile,
    };
  },
};
