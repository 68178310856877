
import { ref, getCurrentInstance, watch } from "vue";
import arraySort from "array-sort";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";

export default {
  name: "TableFiles",
  emits: [
    "current-change",
    "sort",
    "items-per-page-change",
    "eventEmitButton",
    "eventEmitPermission",
  ],
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const buttonRef = ref();
    const element_id = ref("");
    const item_id = ref(null);
    const dialogvideo = ref(false);
    const showaudio = ref(false);
    const url = ref("");
    const headers = ref([
      { column: t("itype"), key: "type" },
      { column: t("iextension"), key: "extension" },
      { column: t("iname"), key: "name" },
    ]);
    const select_all_field = (field) => {
      emit("allFieldSelected", field);
    };

    const onClickDownload = (item) => {
      if (item.type === "document" || item.type === "image") {
        window.open(item.path);
      } else if (item.type === "video") {
        url.value = `${item.path}`;
        dialogvideo.value = true;
      }
    };

    const getUrl = (item) => {
      return `${item.path}`;
    };

    const onClickRemove = (id) => {
      ApiService.delete(`/api/action_files/${id}?force_delete=${true}`).then(
        function ({ data }) {
          emit("removeFile", id);
        }
      );
    };

    return {
      element_id,
      buttonRef,
      item_id,
      headers,
      dialogvideo,
      showaudio,
      url,
      getUrl,
      select_all_field,
      onClickRemove,
      onClickDownload,
    };
  },
};
